import React from 'react';
import { Link } from 'gatsby';
import routes from '../../../../utils/routes';
import CategoryIcon from '../categoryIcon/CategoryIcon';
import './CategoryCard.scss';

const CategoryCard = ({ category }) => {
  return (
    <Link
      className="category-card__link"
      to={routes.PRODUCTS}
      state={{ category: category }}
    >
      <section className="category-card">
        <CategoryIcon category={category[0]} />
        <span className="category-card__title">
          {category[1]}
        </span>
      </section>
    </Link>
  )
}

export default CategoryCard;
