import * as React from "react"

function SvgComponent(props) {
  return (
    <svg data-name="003-drawer" width={74.152} height={74.152} {...props}>
      <path
        data-name="Path 50"
        d="M3.708 1.236h66.737a2.472 2.472 0 012.472 2.472v61.793a2.472 2.472 0 01-2.472 2.472H3.708a2.472 2.472 0 01-2.472-2.472V3.708a2.472 2.472 0 012.472-2.472zm0 0"
        fill="#dfe9f5"
      />
      <path
        data-name="Path 51"
        d="M6.179 6.179h61.794v24.717H6.179zm0 0"
        fill="#fcfcfc"
      />
      <path
        data-name="Path 52"
        d="M4.943 70.445a2.472 2.472 0 002.472 2.472h4.944a2.472 2.472 0 002.472-2.472v-2.472H4.943zm0 0"
        fill="#afafbe"
      />
      <path
        data-name="Path 53"
        d="M58.086 70.445a2.472 2.472 0 002.472 2.472h4.943a2.472 2.472 0 002.472-2.472v-2.472h-9.887zm0 0"
        fill="#afafbe"
      />
      <path
        data-name="Path 54"
        d="M6.179 38.312h61.794v24.717H6.179zm0 0"
        fill="#fcfcfc"
      />
      <path
        data-name="Path 55"
        d="M39.548 16.067a2.472 2.472 0 11-2.472-2.472 2.472 2.472 0 012.472 2.472zm0 0"
        fill="#f1f2f2"
      />
      <path
        data-name="Path 56"
        d="M39.548 48.199a2.472 2.472 0 11-2.472-2.472 2.472 2.472 0 012.472 2.472zm0 0"
        fill="#f1f2f2"
      />
      <path
        data-name="Path 57"
        d="M6.179 38.312v24.717H35.84c-17.761 0-29.661-4.907-29.661-24.717zm0 0"
        fill="#ededed"
      />
      <path
        data-name="Path 58"
        d="M6.179 6.179v24.717H35.84c-17.761 0-29.661-4.907-29.661-24.717zm0 0"
        fill="#ededed"
      />
      <path
        data-name="Path 59"
        d="M59.248 30.896l-13.52-13.52-20.937 20.936h41.871zm0 0"
        fill="#ffdaaa"
      />
      <g data-name="Group 7" fill="#231f20">
        <path
          data-name="Path 60"
          d="M70.445 0H3.708A3.708 3.708 0 000 3.708V65.5a3.708 3.708 0 003.708 3.708v1.236a3.708 3.708 0 003.708 3.708h4.943a3.708 3.708 0 003.708-3.708v-1.235H56.85v1.236a3.708 3.708 0 003.708 3.708H65.5a3.708 3.708 0 003.708-3.708v-1.236h1.236a3.708 3.708 0 003.708-3.709V3.708A3.708 3.708 0 0070.445 0zm-56.85 70.445a1.236 1.236 0 01-1.236 1.236H7.415a1.236 1.236 0 01-1.236-1.236v-1.236h7.415zm53.143 0a1.236 1.236 0 01-1.238 1.236h-4.942a1.236 1.236 0 01-1.236-1.236v-1.236h7.415zm4.943-4.945a1.236 1.236 0 01-1.236 1.236H3.708A1.236 1.236 0 012.472 65.5V3.708a1.236 1.236 0 011.236-1.236h66.737a1.236 1.236 0 011.236 1.236zm0 0"
        />
        <path
          data-name="Path 61"
          d="M4.943 32.132h24.28l-4.943 4.943H4.943v27.19h64.265V37.076h-2.034l-4.943-4.943h6.978V4.943H4.943zm61.794 29.661H7.415v-22.25h59.322zm-9.03-24.717L45.365 24.733l-1.748 1.747 10.6 10.6h-3.492l-8.857-8.857-1.748 1.748 7.11 7.11h-3.5l-5.36-5.36-1.748 1.742 3.613 3.612h-12.46l17.952-17.951 17.952 17.952zM7.415 7.415h59.322V29.66h-6.978L45.727 15.628 31.695 29.66H7.415zm0 0"
        />
        <path
          data-name="Path 62"
          d="M37.077 19.774a3.708 3.708 0 10-3.708-3.707 3.708 3.708 0 003.708 3.707zm0-4.943a1.236 1.236 0 11-1.236 1.236 1.236 1.236 0 011.236-1.236zm0 0"
        />
        <path
          data-name="Path 63"
          d="M37.077 51.906a3.708 3.708 0 10-3.708-3.707 3.708 3.708 0 003.708 3.707zm0-4.944a1.236 1.236 0 11-1.236 1.236 1.236 1.236 0 011.236-1.235zm0 0"
        />
      </g>
    </svg>
  )
}

export default SvgComponent

