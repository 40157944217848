import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={78.475} height={74.152} {...props}>
      <g data-name="008-hanger">
        <g data-name="Group 16">
          <path
            data-name="Path 148"
            d="M68.135 73.005H10.339a9.175 9.175 0 01-5.8-16.282l28.9-23.6a9.206 9.206 0 0111.606 0l28.9 23.6a9.175 9.175 0 01-5.8 16.282zM37.972 38.684l-28.9 23.6a2 2 0 001.265 3.549h57.8a2 2 0 001.265-3.549l-28.9-23.6a2.007 2.007 0 00-2.53 0z"
            fill="#ededed"
          />
          <g data-name="Group 15" fill="#afafbe">
            <path
              data-name="Path 149"
              d="M68.135 65.829h3.055a2 2 0 001.265-3.549l-28.9-23.6a2.007 2.007 0 00-2.53 0l-.264.216 28.634 23.381a2 2 0 01-1.265 3.549z"
            />
            <path
              data-name="Path 150"
              d="M6.287 66.909a9.078 9.078 0 012.839-10.187S39.234 31.45 40.765 31.193a9.192 9.192 0 00-7.332 1.932l-28.9 23.6a9.175 9.175 0 005.8 16.282h4.596a9.078 9.078 0 01-8.642-6.095z"
            />
          </g>
        </g>
        <g data-name="Group 17">
          <path
            data-name="Path 151"
            d="M74.663 55.831l-17.29-14.117a1.15 1.15 0 00-1.454 1.781l17.29 14.117a8.025 8.025 0 01-5.076 14.241H10.341a8.025 8.025 0 01-5.076-14.241l28.9-23.595a8.059 8.059 0 0110.152 0l8.072 6.592a1.15 1.15 0 101.454-1.781l-8.073-6.592a10.339 10.339 0 00-5.38-2.251v-1.81a2.313 2.313 0 011.82-2.247A13.137 13.137 0 0039.555 0 13.134 13.134 0 0026.1 13.133a1.15 1.15 0 102.3 0 10.835 10.835 0 0121.665-.261 10.874 10.874 0 01-8.38 10.815 4.6 4.6 0 00-3.6 4.487v1.81a10.339 10.339 0 00-5.38 2.251L3.811 55.831a10.324 10.324 0 006.529 18.321h57.794a10.324 10.324 0 006.53-18.321z"
          />
          <path
            data-name="Path 152"
            d="M68.134 64.679H19.042a1.15 1.15 0 100 2.3h49.092a3.076 3.076 0 002.967-2.093 3.043 3.043 0 00-.974-3.5l-28.9-23.595a3.158 3.158 0 00-3.984 0l-28.9 23.6a3.042 3.042 0 00-.974 3.493 3.076 3.076 0 002.968 2.1h4.1a1.15 1.15 0 100-2.3h-4.1a.851.851 0 01-.539-1.51l28.286-23.1v2.99a1.15 1.15 0 002.3 0v-2.99l28.285 23.095a.851.851 0 01-.538 1.51z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

