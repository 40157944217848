import React, { useEffect, useState } from 'react';
import { getProductReleases } from '../../../utils/helpers/productFilters';
import ReleaseCard from './releaseCard/ReleaseCard';
import './Releases.scss';

const Releases = () => {
  const [releases, setReleases] = useState([]);

  useEffect(() => {
    const res = getProductReleases();
    setReleases(res);
  }, []);

  return (
    <section className="releases">
      <div className="container">
        <div className="releases__title fadeInLeft">
          <div className="releases__title-highlight">
            Lançamentos
          </div>
          <div className="releases__title-divider" />
        </div>
        {releases && releases.length > 0 && (
          <>
            <div className="releases__cards">
              {releases.map(r => (
                <ReleaseCard
                  key={r.ref}
                  product={r}
                  release
                />
              ))}
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default Releases;
