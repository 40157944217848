import React, { useState, useEffect } from 'react';
import banners from '../../../utils/data/banners';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './Banner.scss';

const Banner = () => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    addFadeAnimation(current);
    const interval = setInterval(() => {
      nextBanner();
    }, 4000);
    return () => clearInterval(interval);
  }, [current]);

  const nextBanner = () => {
    setCurrent(prevState => (prevState < 4 ? prevState += 1 : 0));
  }

  const previousBanner = () => {
    setCurrent(prevState => (prevState > 0 ? prevState -= 1 : 4));
  }

  const addFadeAnimation = (current) => {
    if (!current) {
      const left = document.querySelector(`#gatsby-focus-wrapper > section > main > div > div.banner__content.banner__content--${current}.banner__content--active > div.banner__product--left > img`);
      const right = document.querySelector(`#gatsby-focus-wrapper > section > main > div > div.banner__content.banner__content--${current}.banner__content--active > div.banner__product--right > img`);
      const extra = document.querySelector(`#gatsby-focus-wrapper > section > main > div > div.banner__content.banner__content--${current}.banner__content--active > div.banner__product--extra > img`);

      if (left) {
        left.addEventListener('load', () => {
          const elem = document.querySelector(`#gatsby-focus-wrapper > section > main > div > div.banner__content.banner__content--${current}.banner__content--active > div.banner__product--left`);
          if (elem) elem.style.animation = `fadeInLeft 1s ease-in-out forwards`;
        });
      }

      if (right) {
        right.addEventListener('load', () => {
          const elem = document.querySelector(`#gatsby-focus-wrapper > section > main > div > div.banner__content.banner__content--${current}.banner__content--active > div.banner__product--right`);
          if (elem) elem.style.animation = `fadeInRight 1s ease-in-out forwards`;
        });
      }

      if (right) {
        right.addEventListener('load', () => {
          const elem = document.querySelector(`#gatsby-focus-wrapper > section > main > div > div.banner__content.banner__content--${current}.banner__content--active > div.banner__product--extra`);
          if (elem) elem.style.animation = `fadeInRight 1s ease-in-out forwards`;
        });
      }
    } else {
      const elem = document.querySelector(`#gatsby-focus-wrapper > section > main > div > div.banner__content.banner__content--${current}.banner__content--active > div.banner__product--left`);
      const elem1 = document.querySelector(`#gatsby-focus-wrapper > section > main > div > div.banner__content.banner__content--${current}.banner__content--active > div.banner__product--right`);

      if (elem) elem.style.animation = `fadeInLeft 1s ease-in-out forwards`;
      if (elem1) elem1.style.animation = `fadeInRight 1s ease-in-out forwards`;

    }

  }

  return (
    <div className="banner__section">
      <div style={{ backgroundImage: `url(${banners[0].background})` }}
        className={`banner__content banner__content--${0} ${current === 0 ? 'banner__content--active' : ''}`}
      >
        <div className="banner__text">
          <h2>{banners[0].text}</h2>
        </div>
        <div className="banner__product--left">
          <img className="img-left--zero" src={banners[0].products.left} />
        </div>

        <div className="banner__product--right">
          <img className="img-right--zero" src={banners[0].products.right} />
        </div>

        <div className="banner__product--extra">
          <img className="img-extra--zero" src={banners[0].products.extra} />
        </div>
      </div>

      <div style={{ backgroundImage: `url(${banners[1].background})` }}
        className={`banner__content banner__content--${1} ${current === 1 ? 'banner__content--active' : ''}`}
      >
        <div className="banner__text">
          <h2>{banners[1].text}</h2>
        </div>
        <div className="banner__product--left banner__product--zero">
          <img className="img-left--one" src={banners[1].products.left} />
        </div>

        <div className="banner__product--right">
          <img className="img-right--one" src={banners[1].products.right} />
        </div>
      </div>

      <div style={{ backgroundImage: `url(${banners[2].background})` }}
        className={`banner__content banner__content--${2} ${current === 2 ? 'banner__content--active' : ''}`}
      >
        <div className="banner__text">
          <h2>{banners[2].text}</h2>
        </div>
        <div className="banner__product--left">
          <img className="img-left--two" src={banners[2].products.left} />
        </div>

        <div className="banner__product--right">
          <img className="img-right--two" src={banners[2].products.right} />
        </div>
      </div>

      <div style={{ backgroundImage: `url(${banners[3].background})` }}
        className={`banner__content banner__content--${3} ${current === 3 ? 'banner__content--active' : ''}`}
      >
        <div className="banner__text">
          <h2>{banners[3].text}</h2>
        </div>
        <div className="banner__product--left">
          <img className="img-left--three" src={banners[3].products.left} />
        </div>

        <div className="banner__product--right">
          <img className="img-right--three" src={banners[3].products.right} />
        </div>
      </div>

      <div style={{ backgroundImage: `url(${banners[4].background})` }}
        className={`banner__content banner__content--${4} ${current === 4 ? 'banner__content--active' : ''}`}
      >
        <div className="banner__text">
          <h2>{banners[4].text}</h2>
        </div>
        <div className="banner__product--left">
          <img className="img-left--four" src={banners[4].products.left} />
        </div>

        <div className="banner__product--right">
          <img className="img-right--four" src={banners[4].products.right} />
        </div>
      </div>

      <FaChevronLeft size={36}
        onClick={previousBanner}
        className="banner__control--left"
      />

      <FaChevronRight size={36}
        onClick={nextBanner}
        className="banner__control--right"
      />
      <div className="banner__counter">
        {banners && banners.length && banners.map((b, i) => (
          <div
            key={i}
            className={`banner__counter__single ${i === current || 0 ? 'banner__counter__single--active' : ''}`} />
        ))}
      </div>
    </div >
  )
}

export default Banner;
