import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={75.104} height={70.097} {...props}>
      <g data-name="005-box">
        <g data-name="Group 10">
          <path
            data-name="Path 90"
            d="M44.547 16.273v48.3a4.268 4.268 0 004.268 4.268h17.011a4.268 4.268 0 004.268-4.268v-48.3z"
            fill="#1b262c"
          />
          <path
            data-name="Path 91"
            d="M5.007 16.273v49.455a4.32 4.32 0 004.268 4.37h17.012a4.32 4.32 0 004.268-4.37V16.273z"
            fill="#fff"
          />
          <path
            data-name="Path 92"
            d="M8.762 16.273v48.3a4.268 4.268 0 004.267 4.273h49.044a4.268 4.268 0 004.268-4.268V16.273z"
            fill="#333"
          />
          <g data-name="Group 8" fill="#1b262c">
            <path
              data-name="Path 93"
              d="M5.007 56.328a12.517 12.517 0 0112.517 12.517h-8.25a4.268 4.268 0 01-4.268-4.268v-8.249z"
            />
            <path
              data-name="Path 94"
              d="M70.097 56.328v8.249a4.268 4.268 0 01-4.268 4.268H57.58a12.517 12.517 0 0112.517-12.517z"
            />
          </g>
          <path
            data-name="Path 95"
            d="M34.313 16.273H1.252V3.02a1.768 1.768 0 011.767-1.768h29.526a1.768 1.768 0 011.768 1.768v13.253z"
            fill="#fff"
          />
          <path
            data-name="Path 96"
            d="M73.852 16.273H40.791V3.02a1.768 1.768 0 011.768-1.768h29.526a1.768 1.768 0 011.768 1.768v13.253z"
            fill="#1b262c"
          />
          <path
            data-name="Path 97"
            d="M70.098 16.273H5.007V3.02a1.768 1.768 0 011.767-1.768H68.33a1.768 1.768 0 011.768 1.768v13.253z"
            fill="#1b262c"
          />
          <g data-name="Group 9">
            <path
              data-name="Path 98"
              d="M73.853 17.524H1.252A1.251 1.251 0 010 16.273V3.019A3.023 3.023 0 013.019 0h69.066A3.023 3.023 0 0175.1 3.019v13.254a1.251 1.251 0 01-1.252 1.252zM2.5 15.021h70.1v-12a.516.516 0 00-.516-.516H3.019a.516.516 0 00-.516.516v12z"
            />
            <path
              data-name="Path 99"
              d="M65.829 70.097H9.275a5.527 5.527 0 01-5.52-5.52v-48.3a1.251 1.251 0 011.252-1.252h65.09a1.251 1.251 0 011.252 1.252v48.3a5.527 5.527 0 01-5.52 5.52zM6.255 17.524v47.053a3.02 3.02 0 003.017 3.017h56.557a3.02 3.02 0 003.017-3.017V17.524z"
            />
            <path
              data-name="Path 100"
              d="M17.524 70.098H9.275a5.527 5.527 0 01-5.52-5.52v-8.249a1.251 1.251 0 011.252-1.252 13.785 13.785 0 0113.769 13.766 1.251 1.251 0 01-1.252 1.252zM6.255 57.65v6.927a3.02 3.02 0 003.017 3.017h6.927a11.286 11.286 0 00-9.944-9.944z"
            />
            <path
              data-name="Path 101"
              d="M65.827 70.097h-8.249a1.251 1.251 0 01-1.251-1.254 13.785 13.785 0 0113.769-13.769 1.251 1.251 0 011.252 1.252v8.249a5.527 5.527 0 01-5.52 5.52zm-6.927-2.5h6.927a3.02 3.02 0 003.017-3.017v-6.927a11.286 11.286 0 00-9.943 9.94z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

