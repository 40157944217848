import CategoryEnumerator from "../enumerator/categoryEnumerator";

export const banners = [
  {
    title: 'Baby',
    text: 'Veja nossa coleção baby',
    background: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/banner_baby.jpg',
    category: CategoryEnumerator.BABY,
    products: {
      left: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/378_rosa.png',
      right: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/381_verde.png',
      extra: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/380_azul.png',
    },
  },
  {
    title: 'Brinquedos',
    text: 'Brinquedos para crianças',
    background: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/banner_brinquedos.jpg',
    category: CategoryEnumerator.BRINQUEDOS,
    products: {
      left: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/363_verde.png',
      right: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/312.png',
    },
  },
  {
    title: 'Limpeza',
    text: 'Conheça nossos produtos para ajudar na limpeza',
    background: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/banner_limpeza.jpg',
    category: CategoryEnumerator.LIMPEZA,
    products: {
      left: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/302_vermelho.png',
      right: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/6501_preto.png',
    },
  },
  {
    title: 'Mesa & Cozinha',
    text: 'Veja nossos utensílios para cozinha',
    background: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/banner_cozinha.jpg',
    category: CategoryEnumerator.COZINHA,
    products: {
      left: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/301_preto.png',
      right: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/316_natural.png',
    },
  },
  {
    title: 'Organizadores',
    text: 'Conheça nossos organizadores',
    background: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/banner_organizadores.jpg',
    category: CategoryEnumerator.ORGANIZADORES,
    products: {
      left: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/338_preto.png',
      right: 'https://marbel-plasticos.s3-sa-east-1.amazonaws.com/2020/banner/produtos/682.png',
    },
  }
];

export default banners;
