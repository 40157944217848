import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={75.351} height={70.097} {...props}>
      <g data-name="Group 90">
        <g data-name="013-sale">
          <path
            data-name="Path 175"
            d="M32.772 14.954H17.818a1 1 0 01-1-1v-5.48a8.474 8.474 0 0116.948 0v5.483a1 1 0 01-.994.997zM18.815 12.96h12.96V8.474a6.48 6.48 0 10-12.96 0z"
          />
          <path
            data-name="Path 176"
            d="M47.227 18.941H3.364l2.991-4.984h37.882z"
            fill="#8c6b3b"
          />
          <path
            data-name="Path 177"
            d="M47.227 19.939H3.361a1 1 0 01-.855-1.51l2.991-4.985a1 1 0 01.855-.484h37.884a1 1 0 01.855.484l2.989 4.985a1 1 0 01-.855 1.51zm-42.1-1.994h40.339l-1.794-2.991H6.917z"
          />
          <path
            data-name="Path 181"
            d="M49.221 57.823H1.368l1.994-38.881h43.865z"
            fill="#fbad4a"
          />
          <path
            data-name="Path 182"
            d="M49.221 58.819H1.368a1 1 0 01-1-1.048L2.362 18.89a1 1 0 011-.946h43.865a1 1 0 011 .946l1.994 38.881a1 1 0 01-1 1.048zm-46.8-1.994h45.75l-1.892-36.886H4.312z"
          />
        </g>
        <g data-name="056-tupperware">
          <path
            data-name="Path 579"
            d="M68.995 22.956c0 2.36-8.12 6.016-18.132 6.016-.6 0-1.18-.011-1.753-.047-9.194-.386-16.379-3.75-16.379-5.97 0-1.4 2.9-2.652 7.372-3.434a44.297 44.297 0 012.5-.363l6.707-.921 1.8-.245 8.014 1.168c.877.105 1.718.234 2.5.363 4.475.782 7.372 2.033 7.372 3.434zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 580"
            d="M65.49 22.957c0 2.22-7.185 5.584-16.379 5.97-9.194-.386-16.379-3.75-16.379-5.97 0-1.4 2.9-2.652 7.372-3.434a44.297 44.297 0 012.5-.363l6.706-.923 6.309.923c.876.105 1.717.234 2.5.363 4.475.782 7.372 2.033 7.372 3.434zm0 0"
            fill="#e8edfc"
          />
          <path
            data-name="Path 581"
            d="M74.182 42.876c0 3.026-10.445 6.648-23.319 6.648-.584 0-1.169-.011-1.753-.023-12.057-.3-21.566-3.739-21.566-6.625 0-1.53 2.652-2.909 6.928-3.9.794-.2 1.659-.362 2.558-.526h.012c.128-.012.245-.035.374-.058 2.161 2.57 6.53 3.482 11.694 3.645.572.011 1.157.023 1.753.023 5.5 0 10.3-.689 12.886-3.084a6.568 6.568 0 00.561-.584c.128.023.245.047.374.058h.012c5.759 1 9.486 2.617 9.486 4.428zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 582"
            d="M70.678 42.876c0 2.886-9.51 6.321-21.567 6.625-12.057-.3-21.566-3.739-21.566-6.625 0-1.53 5.023-4.5 9.3-5.491 2.582 2.395 5.012 4.673 10.515 4.673.6 0 1.18-.012 1.753-.023 5.164-.164 9.533-1.075 11.694-3.645.129.023.245.047.374.058h.012c.9.164 1.764.327 2.558.526 4.276.993 6.928 2.372 6.928 3.9zm0 0"
            fill="#e8edfc"
          />
          <path
            data-name="Path 583"
            d="M72.22 50.552L70 58.905l-.023.024-.362 1.385-.012.035c-1.928 4.919-9.58 8.575-18.739 8.575s-16.812-3.656-18.739-8.575l-.013-.035-.362-1.39-.024-.024-2.219-8.353a8.276 8.276 0 00.911.444c3.96 1.694 11.624 2.851 20.445 2.851s16.485-1.157 20.445-2.851a8.276 8.276 0 00.912-.439zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 584"
            d="M69.556 50.552l-2.064 8.353-.021.024-.338 1.385-.011.035c-1.793 4.919-8.909 8.575-17.427 8.575s-15.634-3.656-17.427-8.575l-.011-.035-.337-1.39-.022-.024-2.064-8.353a7.5 7.5 0 00.848.444c3.683 1.694 10.81 2.851 19.013 2.851s15.331-1.157 19.014-2.851a7.54 7.54 0 00.847-.444zm0 0"
            fill="#e8edfc"
          />
          <path
            data-name="Path 585"
            d="M74.182 42.876v5.48c0 .771-.7 1.531-1.963 2.2a8.287 8.287 0 01-.911.444c-3.96 1.694-11.624 2.85-20.445 2.85S34.378 52.693 30.418 51a8.287 8.287 0 01-.911-.444c-1.262-.666-1.963-1.425-1.963-2.2v-5.48c0 3.026 10.444 5.48 23.319 5.48s23.319-2.454 23.319-5.48zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 586"
            d="M67.476 28.927v.012l-1.729 6.5-.012.003-.292 1.086-.012.024a6.581 6.581 0 01-1.121 1.834c-2.407 2.862-7.524 4.837-13.447 4.837s-11.04-1.974-13.447-4.837a6.568 6.568 0 01-1.121-1.834l-.012-.024-.292-1.086-.012-.012-1.729-6.5v-.012a5.709 5.709 0 00.712.35c3.085 1.32 9.043 2.22 15.9 2.22s12.816-.9 15.9-2.22a5.718 5.718 0 00.713-.35zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 587"
            d="M64.59 28.927v.012l-1.55 6.5-.01.012-.258 1.081-.011.024a6.746 6.746 0 01-1.005 1.834c-2.158 2.862-6.746 4.837-12.057 4.837s-9.9-1.974-12.057-4.837a6.745 6.745 0 01-1.005-1.834l-.011-.024-.262-1.086-.01-.012-1.55-6.5v-.012a5 5 0 00.639.35c2.765 1.32 8.108 2.22 14.257 2.22s11.491-.9 14.257-2.22a5 5 0 00.639-.35zm0 0"
            fill="#e8edfc"
          />
          <path
            data-name="Path 588"
            d="M68.995 22.957v4.264c0 .608-.537 1.192-1.519 1.705a5.714 5.714 0 01-.713.35c-3.084 1.32-9.042 2.22-15.9 2.22s-12.816-.9-15.9-2.22a5.707 5.707 0 01-.712-.35c-.981-.514-1.519-1.1-1.519-1.705v-4.264c0 2.36 8.119 4.264 18.132 4.264s18.132-1.9 18.132-4.264zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 589"
            d="M64.135 10.119l-1.227 4.6-.011.012-.234.876v.024a7.281 7.281 0 01-3.54 3.528 19.179 19.179 0 01-16.519 0 7.28 7.28 0 01-3.54-3.528v-.024l-.234-.876-.012-.012-1.226-4.6c.128.082.257.164.4.245 2.5 1.484 7.325 2.488 12.875 2.488s10.374-1 12.874-2.488c.141-.082.269-.164.4-.245zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 590"
            d="M61.739 10.119l-1.113 4.6-.011.012-.212.876v.024a7 7 0 01-3.213 3.528 15.961 15.961 0 01-14.991 0 7 7 0 01-3.213-3.528v-.024l-.212-.876-.011-.012-1.113-4.6c.117.082.234.164.36.245 2.27 1.484 6.648 2.488 11.684 2.488s9.415-1 11.684-2.488c.127-.082.244-.164.36-.245zm0 0"
            fill="#e8edfc"
          />
          <path
            data-name="Path 591"
            d="M65.548 4.627c0 1.9-6.577 4.615-14.685 4.615-.6 0-1.18-.012-1.753-.047-7.279-.35-12.933-2.8-12.933-4.568 0-1.145 2.348-2.162 5.982-2.792a50.392 50.392 0 016.951-.643 83.194 83.194 0 011.753-.023 52.534 52.534 0 018.7.666c3.633.631 5.981 1.647 5.981 2.792zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 592"
            d="M62.043 4.626c0 1.764-5.654 4.217-12.933 4.568-7.279-.35-12.933-2.8-12.933-4.568 0-1.145 2.348-2.162 5.982-2.792a50.391 50.391 0 016.951-.646 50.391 50.391 0 016.951.643c3.633.631 5.982 1.647 5.982 2.792zm0 0"
            fill="#e8edfc"
          />
          <path
            data-name="Path 593"
            d="M65.548 4.627v3.446a2.686 2.686 0 01-1.413 2.045 8.44 8.44 0 01-.4.245c-2.5 1.484-7.325 2.488-12.874 2.488s-10.375-1-12.875-2.488c-.14-.082-.268-.164-.4-.245a2.688 2.688 0 01-1.414-2.045V4.627c0 1.9 6.577 3.446 14.686 3.446s14.685-1.542 14.685-3.446zm0 0"
            fill="#cad9fc"
          />
          <path
            data-name="Path 594"
            d="M27.615 42.545a.937.937 0 00-.071.331v5.48c0 .771.7 1.531 1.963 2.2l2.219 8.353.024.024.362 1.39.012.035a8.989 8.989 0 002.055 3.015c3.073 3.323 8.86 5.56 15.516 5.56.19 0 .375-.011.563-.015s.4.015.605.015c.408 0 .809-.016 1.211-.03-6.179-.236-11.492-2.4-14.39-5.53a8.989 8.989 0 01-2.055-3.015l-.012-.035-.363-1.39-.023-.024-2.222-8.357c-1.262-.666-1.963-1.425-1.963-2.2v-5.48a.937.937 0 01.072-.331 3.067 3.067 0 011.549-1.669 22.629 22.629 0 017.562-3.461 6.365 6.365 0 01-.432-.86l-.012-.024-.292-1.086-.012-.012-1.729-6.5v-.012c-.981-.514-1.519-1.1-1.519-1.705v-4.255c0-1.4 2.9-2.652 7.372-3.434a42.043 42.043 0 012.18-.321c-.026-.016-.058-.026-.084-.042a7 7 0 01-3.21-3.527v-.024l-.212-.876-.011-.012-.533-2.2-.639-2.4a2.686 2.686 0 01-1.413-2.045V4.627c0-1.145 2.348-2.162 5.982-2.792a50.264 50.264 0 016.8-.636c-.528-.013-1.054-.03-1.6-.03-.6 0-1.18.011-1.753.023a50.391 50.391 0 00-6.953.641c-3.633.631-5.982 1.647-5.982 2.792v3.448a2.688 2.688 0 001.414 2.045l.639 2.4.533 2.2.011.012.212.876v.027a6.993 6.993 0 003.213 3.528c.026.016.058.027.084.042-.759.095-1.5.2-2.18.32-4.475.782-7.372 2.033-7.372 3.434v4.264c0 .608.537 1.192 1.519 1.705v.012l1.729 6.5.012.012.292 1.086.012.024a6.3 6.3 0 00.432.86 22.625 22.625 0 00-7.562 3.461 3.07 3.07 0 00-1.549 1.669zm0 0"
            fill="#fff"
          />
          <path
            data-name="Path 595"
            d="M67.237 38.971h.017-.017zm0 0"
            fill="#fff"
          />
          <path
            data-name="Path 596"
            d="M64.1 38.448v-.012h1.168zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 597"
            d="M64.111 38.46v-.012h1.169zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 598"
            d="M36.447 38.46v-.012h1.168zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 599"
            d="M36.459 38.448v-.012h1.168zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 600"
            d="M42.743 48.599h-.013v-1.168zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 601"
            d="M58.995 48.599h-.013v-1.168zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 602"
            d="M61.214 19.937l-.011-.009.826-.826zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 603"
            d="M40.513 19.936l-.826-.826.836.817zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 604"
            d="M44.548 27.543h-.011v-1.169zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 605"
            d="M57.19 27.543h-.012v-1.169zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 606"
            d="M59.192 2.28l-.009-.008.768-.879zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 607"
            d="M42.534 2.28l-.769-.879.778.871zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 608"
            d="M45.752 8.446h-.01V7.277zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 609"
            d="M55.983 8.446h-.01V7.277zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 610"
            d="M64.691 39.62a1.146 1.146 0 01-.816-.339l-.014-.014a1.159 1.159 0 11.829.353zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 611"
            d="M37.025 39.623a1.175 1.175 0 11.84-.356l-.014.014a1.166 1.166 0 01-.826.342zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 612"
            d="M50.863 49.523c-8.47 0-24.487-1.39-24.487-6.648 0-2.493 3.517-4.369 10.454-5.579.181-.019.264-.036.347-.051l.24 1.144.17 1.157c-.151.026-.3.051-.452.065-6.975 1.22-8.421 2.886-8.421 3.264 0 1.289 7.536 4.31 22.151 4.31s22.151-3.021 22.151-4.31c0-.379-1.446-2.045-8.519-3.277-.053 0-.2-.026-.355-.052a1.184 1.184 0 01-.963-1.357 1.148 1.148 0 011.336-.949c.119.02.2.037.285.044 7.034 1.222 10.552 3.1 10.552 5.591 0 5.258-16.017 6.648-24.487 6.648zm0 0"
          />
          <path
            data-name="Path 613"
            d="M50.864 55.013c-8.659 0-16.671-1.129-20.909-2.947-2.372-1.017-3.575-2.265-3.575-3.709a1.169 1.169 0 012.337 0c0 .164.4.8 2.158 1.56 3.967 1.7 11.626 2.757 19.989 2.757s16.021-1.061 19.986-2.761c1.762-.756 2.158-1.4 2.158-1.56a1.169 1.169 0 012.337 0c0 1.443-1.2 2.692-3.575 3.707-4.237 1.817-12.249 2.947-20.908 2.947zm0 0"
          />
          <path
            data-name="Path 614"
            d="M27.549 49.525a1.168 1.168 0 01-1.168-1.169v-5.487a1.169 1.169 0 012.337 0v5.487a1.168 1.168 0 01-1.169 1.169zm0 0"
          />
          <path
            data-name="Path 615"
            d="M74.178 49.525a1.168 1.168 0 01-1.168-1.169v-5.487a1.169 1.169 0 012.337 0v5.487a1.168 1.168 0 01-1.169 1.169zm0 0"
          />
          <path
            data-name="Path 616"
            d="M42.744 49.178h-.013a1.168 1.168 0 11.013 0zm0 0"
          />
          <path
            data-name="Path 617"
            d="M58.996 49.178h-.013a1.169 1.169 0 11.014 0zm0 0"
          />
          <path
            data-name="Path 618"
            d="M31.73 60.089a1.144 1.144 0 01-1.03-.635l-.018-.024a1.163 1.163 0 111.043.663zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 619"
            d="M69.98 60.1a1.174 1.174 0 11.531-2.227 1.155 1.155 0 01.529 1.554l-.014.027a1.169 1.169 0 01-1.046.646zm0 0"
            fill="#428dff"
          />
          <path
            data-name="Path 620"
            d="M33.217 59.932a10.421 10.421 0 004.67 4.77 23.315 23.315 0 006.763 2.447 31.6 31.6 0 006.213.612v2.337a33.956 33.956 0 01-6.68-.659 25.627 25.627 0 01-7.456-2.708 12.657 12.657 0 01-5.687-5.948zm0 0"
            fill="#fff"
          />
          <g data-name="Group 89">
            <path
              data-name="Path 621"
              d="M35.835 64.771a1.229 1.229 0 00-.159-1.608 8.607 8.607 0 01-2.458-3.231l-2.176.851a10.9 10.9 0 003.115 4.155 1.128 1.128 0 001.678-.167zm0 0"
            />
            <path
              data-name="Path 622"
              d="M50.863 70.097V67.76a31.6 31.6 0 01-6.213-.612l-1.092-.252a1.2 1.2 0 00-1.407.829 1.147 1.147 0 00.848 1.439l1.185.273a33.911 33.911 0 006.68.659zm0 0"
            />
            <path
              data-name="Path 623"
              d="M39.352 65.458a1.179 1.179 0 00-.854-.045l-.2.1a.929.929 0 00-.189.14.77.77 0 00-.159.156 1.725 1.725 0 00-.13.2 1.154 1.154 0 00-.074.891 1.237 1.237 0 00.251.4 1.217 1.217 0 001.315.318 1.141 1.141 0 00.378-.258 1.109 1.109 0 00.251-.375 1.31 1.31 0 00.006-.9 1.153 1.153 0 00-.595-.627zm0 0"
            />
            <path
              data-name="Path 624"
              d="M32.11 61.508a1.14 1.14 0 01-1.089-.78l-.014-.041a1.164 1.164 0 111.1.821zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 625"
              d="M50.863 70.097a1.169 1.169 0 110-2.337c8.45 0 15.872-3.293 17.646-7.829a1.186 1.186 0 011.492-.694 1.15 1.15 0 01.718 1.448l-.013.041c-2.165 5.537-10.317 9.371-19.843 9.371zm0 0"
            />
            <path
              data-name="Path 626"
              d="M32.11 61.506a1.139 1.139 0 01-1.089-.78l-.383-1.418a1.048 1.048 0 01-.035-.1l-2.222-8.353a1.168 1.168 0 112.258-.6l2.2 8.278a.981.981 0 01.034.1l.37 1.385a1.187 1.187 0 01-.786 1.441 1.235 1.235 0 01-.348.051zm0 0"
            />
            <path
              data-name="Path 627"
              d="M70.741 60.617l-1.143-.26-1.108-.37.363-1.358c.01-.039.024-.076.036-.114l2.2-8.265a1.168 1.168 0 112.258.6l-2.222 8.352a1.069 1.069 0 01-.037.115zm0 0"
            />
            <path
              data-name="Path 628"
              d="M61.619 20.69a1.151 1.151 0 01-.819-.34l-.01-.01a1.161 1.161 0 11.829.351zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 629"
              d="M40.101 20.694a1.181 1.181 0 11.836-.352l-.01.01a1.165 1.165 0 01-.826.342zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 630"
              d="M50.863 28.389c-7.168 0-19.3-1.145-19.3-5.432 0-3 5.928-4.352 10.9-4.957a1.169 1.169 0 01.282 2.32c-6.881.835-8.73 2.319-8.849 2.671.356 1.026 6.5 3.061 16.968 3.061s16.611-2.035 16.968-3.126c-.119-.287-1.968-1.771-8.849-2.606a1.169 1.169 0 11.282-2.32c4.973.6 10.9 1.954 10.9 4.957 0 4.286-12.133 5.431-19.3 5.431zm0 0"
            />
            <path
              data-name="Path 631"
              d="M50.863 32.661c-6.769 0-13.039-.887-16.365-2.312-1.949-.835-2.938-1.886-2.938-3.122a1.169 1.169 0 112.337 0c0-.009.151.388 1.522.975 3.054 1.31 8.972 2.123 15.444 2.123s12.39-.813 15.444-2.123c1.347-.578 1.525-1.008 1.526-1.011a1.151 1.151 0 011.166-1.15 1.182 1.182 0 011.166 1.187c0 1.235-.989 2.286-2.938 3.122-3.325 1.426-9.6 2.312-16.364 2.312zm0 0"
            />
            <path
              data-name="Path 632"
              d="M32.73 28.395a1.168 1.168 0 01-1.169-1.168V22.96a1.169 1.169 0 012.337 0v4.264a1.168 1.168 0 01-1.168 1.171zm0 0"
            />
            <path
              data-name="Path 633"
              d="M68.998 28.395a1.168 1.168 0 01-1.168-1.168V22.96a1.169 1.169 0 012.337 0v4.264a1.168 1.168 0 01-1.169 1.171zm0 0"
            />
            <path
              data-name="Path 634"
              d="M44.549 28.128h-.011a1.168 1.168 0 11.011 0zm0 0"
            />
            <path
              data-name="Path 635"
              d="M57.189 28.128h-.011a1.169 1.169 0 11.011 0zm0 0"
            />
            <path
              data-name="Path 636"
              d="M35.981 36.611a1.149 1.149 0 01-1.044-.66l-.011-.021a1.168 1.168 0 111.562.567 1.188 1.188 0 01-.507.116zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 637"
              d="M65.733 36.619a1.176 1.176 0 111.067-.69l-.01.021a1.169 1.169 0 01-1.057.669zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 638"
              d="M50.864 44.395c-7.514 0-13.953-3.046-15.659-7.409l1.077-.458 1.11-.364c1.331 3.4 7 5.9 13.472 5.9s12.141-2.492 13.483-5.923a1.181 1.181 0 011.488-.69 1.153 1.153 0 01.721 1.447l-.011.033c-1.724 4.417-8.167 7.464-15.681 7.464zm0 0"
            />
            <path
              data-name="Path 639"
              d="M36.277 37.714a1.143 1.143 0 01-1.1-.789l-.3-1.105a.899.899 0 01-.028-.09l-1.729-6.5a1.168 1.168 0 112.258-.6l1.711 6.43c.011.029.02.058.027.085l.288 1.077a1.173 1.173 0 01-1.133 1.489zm0 0"
            />
            <path
              data-name="Path 640"
              d="M66.574 36.83l-1.139-.268-1.11-.364.281-1.048a1.72 1.72 0 01.029-.095l1.709-6.421a1.168 1.168 0 112.258.6l-1.729 6.5a1.897 1.897 0 01-.03.1zm0 0"
            />
            <path
              data-name="Path 641"
              d="M59.57 3.007a1.155 1.155 0 01-.763-.286l-.009-.008a1.165 1.165 0 11.772.294zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 642"
              d="M42.151 3.01a1.179 1.179 0 11.778-.3l-.009.008a1.168 1.168 0 01-.769.289zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 643"
              d="M50.863 9.245c-1.623 0-15.848-.13-15.848-4.623S49.241 0 50.863 0s15.851.13 15.851 4.622-14.229 4.623-15.851 4.623zM37.414 4.622c.812.878 5.654 2.286 13.449 2.286S63.5 5.5 64.314 4.622c-.813-.876-5.655-2.285-13.449-2.285S38.227 3.746 37.414 4.622zm0 0"
            />
            <path
              data-name="Path 644"
              d="M50.863 14.019c-5.641 0-10.677-.989-13.471-2.646-1.965-1.166-2.378-2.437-2.378-3.3a1.169 1.169 0 112.337 0c0 .269.324.748 1.233 1.287 2.412 1.431 7.118 2.319 12.279 2.319s9.867-.888 12.28-2.319c.908-.539 1.232-1.018 1.232-1.287a1.169 1.169 0 112.337 0c0 .86-.412 2.131-2.377 3.3-2.794 1.657-7.831 2.646-13.471 2.646zm0 0"
            />
            <path
              data-name="Path 645"
              d="M36.184 9.244a1.168 1.168 0 01-1.17-1.168V4.622a1.169 1.169 0 012.337 0v3.455a1.168 1.168 0 01-1.167 1.167zm0 0"
            />
            <path
              data-name="Path 646"
              d="M65.543 9.244a1.168 1.168 0 01-1.168-1.168V4.622a1.169 1.169 0 012.337 0v3.455a1.168 1.168 0 01-1.169 1.167zm0 0"
            />
            <path
              data-name="Path 647"
              d="M45.752 9.029h-.009a1.169 1.169 0 11.009 0zm0 0"
            />
            <path
              data-name="Path 648"
              d="M55.984 9.029h-.009a1.169 1.169 0 11.009 0zm0 0"
            />
            <path
              data-name="Path 649"
              d="M38.817 15.896a1.155 1.155 0 01-1.036-.639l-.009-.017a1.162 1.162 0 111.044.656zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 650"
              d="M62.899 15.902a1.172 1.172 0 11.528-2.221 1.16 1.16 0 01.527 1.558l-.009.017a1.168 1.168 0 01-1.046.646zm0 0"
              fill="#428dff"
            />
            <path
              data-name="Path 651"
              d="M50.863 22.197c-6.171 0-11.47-2.525-12.884-6.139l1.079-.452 1.111-.362c1.041 2.659 5.544 4.616 10.694 4.616s9.653-1.958 10.708-4.654a1.18 1.18 0 011.486-.687 1.154 1.154 0 01.721 1.448l-.009.026c-1.437 3.678-6.735 6.2-12.906 6.2zm0 0"
            />
            <path
              data-name="Path 652"
              d="M39.064 16.787a1.16 1.16 0 01-1.154-.972l-.179-.67a1.281 1.281 0 01-.042-.126l-1.226-4.6a1.168 1.168 0 112.257-.6l1.2 4.49a1.06 1.06 0 01.042.126l.234.877a1.157 1.157 0 01.04.3 1.178 1.178 0 01-1.172 1.175zm0 0"
            />
            <path
              data-name="Path 653"
              d="M62.663 16.786a1.159 1.159 0 01-1.169-1.157v-.024a1.162 1.162 0 01.04-.3l.234-.876a1.079 1.079 0 01.044-.134l1.194-4.483a1.168 1.168 0 112.258.6l-1.226 4.6a1.3 1.3 0 01-.044.132l-.193.726a1.176 1.176 0 01-1.138.916zm0 0"
            />
            <path
              data-name="Path 654"
              d="M39.181 63.088a1.176 1.176 0 01-.37-.06 6.264 6.264 0 01-4.3-5.781 1.169 1.169 0 112.337-.007 3.936 3.936 0 002.707 3.572 1.168 1.168 0 01-.37 2.276zm0 0"
            />
          </g>
        </g>
        <g data-name="012-glass-of-water" stroke="#000">
          <path
            data-name="Path 169"
            d="M6.662 41.339l12.788-4.831a2.1 2.1 0 012.8 1.534l4.855 23.087-11.754 4.442a2.219 2.219 0 01-2.732-1.012l-10.38-18.99a2.1 2.1 0 011.1-2.976l.245-.093"
            fill="#f1536e"
          />
          <path
            data-name="Path 170"
            d="M15.351 65.571l11.755-4.441-.823-3.913-2.693 1.024-11.9 4.5c-.013-.023-.041-.03-.08-.026l1.01 1.848a2.219 2.219 0 002.731 1.008z"
            fill="#d61637"
          />
          <path
            data-name="Path 171"
            d="M26.788 60.773l-.758-3.605-3.426-16.292c-1.333-.1-5.424-.295-7.109-.372a.665.665 0 00-.694.7c.2 3.394 1.024 17.611 1.538 23.316l.018.192 3.47-1.311z"
            fill="#02acab"
            opacity={0.1}
          />
          <path
            data-name="Path 172"
            d="M17.069 44.56l1.725 21.572a2.219 2.219 0 002.081 2.038l10.646.631a2.219 2.219 0 002.3-1.746l4.588-21.2a2.1 2.1 0 00-1.931-2.544l-17.19-1.019a2.1 2.1 0 00-2.219 2.268z"
            fill="#f1536e"
          />
          <path
            data-name="Path 173"
            d="M18.69 64.089l-.047.14.152 1.9a2.219 2.219 0 002.081 2.038l10.646.631a2.219 2.219 0 002.3-1.746l.493-2.278z"
            fill="#d61637"
          />
          <path
            data-name="Path 174"
            d="M39.391 43.107a.579.579 0 00-.427-.228l-15.3-.906c-.147-.7-.3-1.407-.454-2.1-.306-1.393-.623-2.833-.889-4.284 0-.023-.008-.046-.013-.07a.581.581 0 00-.06-.163h0-.007 0a.58.58 0 00-.132-.15H22.1a.582.582 0 00-.082-.054l-.044-.021h0-.018a.577.577 0 00-.09-.027h-.044a.576.576 0 00-.285.034h0c-2.184.846-4.431 1.678-6.6 2.482-2.786 1.031-5.667 2.1-8.481 3.218a.579.579 0 10.428 1.076c2.8-1.115 5.675-2.179 8.455-3.207 1.964-.727 3.988-1.476 5.974-2.239.243 1.255.514 2.492.779 3.695.128.584.26 1.182.386 1.776l-5.559-.329a.579.579 0 00-.611.624l1.411 17.647c-1.651.64-3.322 1.265-4.938 1.87l-.887.332a605.573 605.573 0 00-3.954-7.155c-.951-1.767-1.9-3.51-2.817-5.2-1.06-1.972-2.146-3.97-3.223-5.971l1.89-.714a.579.579 0 10-.409-1.083l-2.233.843c-.086.026-.162.058-.229.085l-.055.022h0a.578.578 0 00-.258.2h0a.558.558 0 00-.035.057h0a.578.578 0 00-.071.331h0a.577.577 0 00.068.217c1.175 2.2 2.37 4.394 3.526 6.519.917 1.687 1.866 3.431 2.817 5.2l.005.009c2.083 3.7 4.035 7.294 6.016 10.953a.579.579 0 00.608.295 24.021 24.021 0 003.644-1.187c.311-.127.628-.256.945-.379l.237 2.967a.579.579 0 00.543.532l14.471.858h.035a.579.579 0 00.566-.456L39.5 43.58a.579.579 0 00-.109-.473zM13.752 65.48l-1.306-2.407.734-.275a468.244 468.244 0 004.628-1.75l.231 2.885c-.439.162-3.223 1.218-4.287 1.547zm19.207 2.827l-13.473-.8-.224-2.8 12.093.717h.035a.579.579 0 00.034-1.157l-12.256-.729-1.661-20.77 20.714 1.228z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

