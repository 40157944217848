import React from 'react';
import { navigate } from 'gatsby';
import Select from 'react-select';
import { MdSearch } from 'react-icons/md';
import { useState } from 'react';
import { getGroupProductSelect, getProductByReference } from '../../../utils/helpers/productFilters';
import routes from '../../../utils/routes';
import './Search.scss';

const Search = () => {
  const [filteredOptions] = useState(getGroupProductSelect());

  const selectProduct = (e) => {
    const product = getProductByReference(e.value);
    navigate(routes.PRODUCTS, { state: { product: product } });
  }

  return (
    <section className="search">
      <div className="container">
        <div className="search__wrapper">
          <div className="search__input">
            <div className="search__input__label">
              <MdSearch /> Buscar um produto
            </div>
            <Select
              className="search__input__select"
              isSearchable
              options={filteredOptions}
              onChange={selectProduct}
              placeholder="Buscar por nome" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Search;
