import React from 'react';
import { CategoryEnumerator } from '../../../../utils/enumerator/categoryEnumerator';
import MarbelIcon from '../../../shared/icons/MarbelIcon';
import AcessoriosIcon from '../../../shared/icons/category/AcessoriosIcon';
import BabyIcon from '../../../shared/icons/category/BabyIcon';
import BlackIcon from '../../../shared/icons/category/BlackIcon';
import BrinquedosIcon from '../../../shared/icons/category/BrinquedosIcon';
import CaminhaoIcon from '../../../shared/icons/category/CaminhaoIcon';
import ColorIcon from '../../../shared/icons/category/ColorIcon';
import CozinhaIcon from '../../../shared/icons/category/CozinhaIcon';
import KitsIcon from '../../../shared/icons/category/KitsIcon';
import LimpezaIcon from '../../../shared/icons/category/LimpezaIcon';
import OrganizadoresIcon from '../../../shared/icons/category/OrganizadoresIcon';

const CategoryIcon = ({ category }) => {
  switch (category) {
    case CategoryEnumerator.ACESSORIOS:
      return <AcessoriosIcon />;
    case CategoryEnumerator.BABY:
      return <BabyIcon />;
    case CategoryEnumerator.BLACK:
      return <BlackIcon />;
    case CategoryEnumerator.BRINQUEDOS:
      return <CaminhaoIcon />;
    case CategoryEnumerator.COLOR:
      return <ColorIcon />;
    case CategoryEnumerator.COZINHA:
      return <CozinhaIcon />;
    case CategoryEnumerator.KITS:
      return <KitsIcon />;
    case CategoryEnumerator.LIMPEZA:
      return <LimpezaIcon />;
    case CategoryEnumerator.ORGANIZADORES:
      return <OrganizadoresIcon />;
    default:
      return <MarbelIcon />;
  }
}

export default CategoryIcon;
