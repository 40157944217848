import React from 'react';
import { Link } from 'gatsby';
import { CategoryEnumerator, CategoryMap } from '../../../utils/enumerator/categoryEnumerator';
import CategoryCard from './categoryCard/CategoryCard';
import { FaChevronRight } from 'react-icons/fa';
import routes from '../../../utils/routes';
import './Mosaic.scss';

const Mosaic = () => {
  return (
    <section className="mosaic">
      <div className="container">
        <div className="mosaic__title">
          <div className="mosaic__title-text">
            Nossos
        </div>
          <div className="mosaic__title-highlight">
            Produtos
        </div>
          <div className="mosaic__title-divider" />
        </div>
        <div className="mosaic__cards">
          {Array.from(CategoryMap)
            .filter(c => c[0] !== CategoryEnumerator.ALL)
            .map((r) => (
              <div
                key={r[0]}
                className={"mosaic__cards__single"}>
                <CategoryCard
                  category={r}
                />
              </div>
            ))}
          <div className="mosaic__action">
            <Link
              className="category-card__link"
              to={routes.PRODUCTS}
            >
              <button
                type="button"
                className="mosaic__action-button"
              >
                ver todos
                <FaChevronRight size={14} />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Mosaic;
