import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import routes from '../../../../utils/routes';
import './ReleaseCard.scss';

const ReleaseCard = ({ product }) => {

  useEffect(() => {
    const releases = document.querySelectorAll('.release-card');

    const appearOnScroll = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) return;
        else {
          entry.target.style.animation
            = `fadeInUp 1s ease-in-out ${entry.target.dataset.delay ? entry.target.dataset.delay : ''} forwards`;
        }
      })
    }, { threshold: 0.75 });

    releases.forEach(elem => {
      appearOnScroll.observe(elem);
    });
  })

  return (
    <Link
      className="category-card__link"
      to={routes.PRODUCTS}
      state={{ product: product }}
    >
      <div className="release-card">
        <header>
          <img
            className="release-image"
            src={product.images[0]}
          />
        </header>
        <div className="release-card__info">
          <span className="release-card__name">
            {product.name}
          </span>
        </div>
      </div>
    </Link>
  )
}

export default ReleaseCard;
