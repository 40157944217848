import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width={74.152} height={74.152} {...props}>
      <g data-name="002-paint">
        <path
          data-name="Path 27"
          d="M64.373 37.077c0-19.874-12.221-35.986-27.3-35.986a35.986 35.986 0 110 71.971c15.076 0 27.3-16.109 27.3-35.985z"
          fill="#fd805e"
        />
        <path
          data-name="Path 28"
          d="M37.076 1.091c17.475 0 31.641 16.111 31.641 35.986S54.55 73.062 37.076 73.062a6.281 6.281 0 110-12.562 6.281 6.281 0 100-12.562H11.951A10.861 10.861 0 011.09 37.077 35.986 35.986 0 0137.076 1.091z"
          fill="#fea686"
        />
        <path
          data-name="Path 29"
          d="M16.559 32.469a6.5 6.5 0 00-5.322-1.868v12.952a6.5 6.5 0 005.322-1.868 3.258 3.258 0 000-4.608 3.258 3.258 0 000-4.608z"
          fill="#2d99e9"
        />
        <path
          data-name="Path 30"
          d="M13.169 37.077c0-3.355-.845-6.117-1.932-6.476a6.515 6.515 0 000 12.952c1.087-.359 1.932-3.121 1.932-6.476z"
          fill="#12b5ff"
        />
        <path
          data-name="Path 31"
          d="M19.31 12.794v13.033a3.258 3.258 0 003.256-3.258 3.258 3.258 0 003.258-3.258 6.516 6.516 0 00-6.514-6.517z"
          fill="#47bf70"
        />
        <path
          data-name="Path 32"
          d="M21.483 19.31c0-3.6-.973-6.516-2.172-6.516a6.517 6.517 0 000 13.033c1.199 0 2.172-2.918 2.172-6.517z"
          fill="#5ede8a"
        />
        <path
          data-name="Path 33"
          d="M41.684 7.345a6.5 6.5 0 00-4.608-1.909l1.227 11.892a3.255 3.255 0 003.38-.768 6.516 6.516 0 00.001-9.215z"
          fill="#fff2d4"
        />
        <path
          data-name="Path 34"
          d="M37.076 5.436a6.516 6.516 0 00-4.608 11.124 3.258 3.258 0 004.608 0 3.239 3.239 0 001.227.768 13.253 13.253 0 00.945-5.376c0-3.598-.972-6.516-2.172-6.516z"
          fill="#fff"
        />
        <path
          data-name="Path 35"
          d="M66.809 32.469a6.5 6.5 0 00-4.608-1.909v13.033a6.516 6.516 0 004.608-11.124z"
          fill="#db3916"
        />
        <path
          data-name="Path 36"
          d="M64.373 37.077c0-3.6-.973-6.516-2.172-6.516a6.5 6.5 0 00-4.608 1.909 3.258 3.258 0 000 4.608 3.258 3.258 0 000 4.608 6.5 6.5 0 004.608 1.909c1.2-.002 2.172-2.92 2.172-6.518z"
          fill="#fc5a37"
        />
        <path
          data-name="Path 37"
          d="M21.038 60.789l2.307-9.981L1.279 72.874c6.536 1.632 15.44-7.766 19.759-12.085z"
          fill="#071e61"
        />
        <path
          data-name="Path 38"
          d="M17.968 57.719l5.376-6.911-9.981 2.307C9.044 57.434-.354 66.338 1.278 72.874c3.269-1.635 12.37-10.836 16.69-15.155z"
          fill="#12398f"
        />
        <path
          data-name="Path 39"
          d="M28.486 41.051L17.964 57.717l3.072 3.072 14.46-12.728z"
          fill="#a8cfff"
        />
        <path
          data-name="Path 40"
          d="M26.089 38.652l-12.728 14.46 4.608 4.608 14.46-12.728z"
          fill="#adeeff"
        />
        <path
          data-name="Path 41"
          d="M71.917 8.373a4.344 4.344 0 00-.128-6.01L27.723 40.286l7.744 7.744z"
          fill="#ffc85f"
        />
        <path
          data-name="Path 42"
          d="M65.78 2.236l-39.658 36.45 6.268 6.268 39.4-42.59a4.344 4.344 0 00-6.009-.128z"
          fill="#ffe278"
        />
        <path
          data-name="Path 43"
          d="M36.942 46.43l-3.072-3.072-3.072 3.072 3.072 3.072a2.172 2.172 0 003.072-3.072z"
          fill="#adeeff"
        />
        <path
          data-name="Path 44"
          d="M33.866 46.429a2.172 2.172 0 000-3.072l-6.144-6.144a2.172 2.172 0 10-3.072 3.072l6.144 6.144a2.172 2.172 0 003.072 0z"
          fill="#e0f8ff"
        />
        <path
          data-name="Path 45"
          d="M17.327 31.701a7.6 7.6 0 100 10.752 4.349 4.349 0 00.613-5.376 4.349 4.349 0 00-.613-5.376zm-1.536 6.144a2.172 2.172 0 010 3.072 5.43 5.43 0 110-7.68 2.172 2.172 0 010 3.072 1.086 1.086 0 000 1.536z"
        />
        <path
          data-name="Path 46"
          d="M26.913 19.311a7.6 7.6 0 10-7.6 7.6 4.352 4.352 0 004.234-3.369 4.352 4.352 0 003.366-4.231zm-5.43 3.258a2.175 2.175 0 01-2.172 2.172 5.43 5.43 0 115.43-5.43 2.175 2.175 0 01-2.172 2.172 1.086 1.086 0 00-1.086 1.086z"
        />
        <path
          data-name="Path 47"
          d="M34.772 18.601a4.316 4.316 0 002.3-.659 4.348 4.348 0 005.376-.613 7.6 7.6 0 10-10.752 0 4.315 4.315 0 003.076 1.272zM33.236 8.112a5.43 5.43 0 117.68 7.68 2.172 2.172 0 01-3.072 0 1.086 1.086 0 00-1.536 0 2.172 2.172 0 01-3.072 0 5.431 5.431 0 010-7.68z"
        />
        <path
          data-name="Path 48"
          d="M56.825 42.452a7.6 7.6 0 100-10.752 4.349 4.349 0 00-.613 5.376 4.349 4.349 0 00.613 5.376zm1.536-6.144a2.172 2.172 0 010-3.072 5.43 5.43 0 110 7.68 2.172 2.172 0 010-3.072 1.086 1.086 0 000-1.536z"
        />
        <path
          data-name="Path 49"
          d="M72.717 9.108a5.43 5.43 0 00-7.672-7.672l-6.162 5.663A37.071 37.071 0 000 37.077a11.96 11.96 0 0011.951 11.947h3.566l-2.945 3.347-.678.675A75.86 75.86 0 003.8 62.082C.634 66.473-.536 70.089.225 73.137a1.086 1.086 0 00.791.791 7.656 7.656 0 001.863.224c2.635 0 5.664-1.255 9.191-3.8a75.883 75.883 0 009.036-8.093l.675-.678 12.163-10.7a3.256 3.256 0 004.379-4.352l27.23-29.63a34.551 34.551 0 016.423 20.174 34.939 34.939 0 01-34.9 34.9 5.2 5.2 0 110-10.39 7.365 7.365 0 005.336-12.443 1.087 1.087 0 00-1.573 1.5 5.193 5.193 0 01-3.763 8.774 7.367 7.367 0 100 14.735 37.07 37.07 0 0029.978-58.88zm-6.2-6.073a3.258 3.258 0 014.6 4.6L36.906 44.861l-7.614-7.614zM11.951 46.852a9.786 9.786 0 01-9.775-9.775 34.939 34.939 0 0134.9-34.9 34.551 34.551 0 0120.175 6.422l-29.626 27.23a3.256 3.256 0 00-4.352 4.379l-5.845 6.643zm-9.74 25.09c-.643-5.533 6.944-13.108 11.152-17.293L19.5 60.79C15.319 64.999 7.746 72.586 2.211 71.942zm33.96-23.208a1.088 1.088 0 01-1.532 0l-.011-.011-6.137-6.137a1.086 1.086 0 10-1.536 1.536l5.325 5.325-11.194 9.852-6.233-6.233 10.613-12.067a1.086 1.086 0 00-.047-1.485 1.086 1.086 0 111.536-1.536l9.216 9.221a1.088 1.088 0 010 1.536z"
        />
      </g>
    </svg>
  )
}

export default SvgComponent

