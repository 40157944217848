import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Banner from "../components/home/banner/Banner";
import Releases from "../components/home/releases/Releases";
import Mosaic from "../components/home/mosaic/Mosaic";
import Search from "../components/home/search/Search";
import ItensPattern from '../components/shared/icons/pattern/ItensPattern';

const IndexPage = () => {
  return (
    <Layout home>
      <SEO title="Home" />
      <Banner />
      <Search />
      <ItensPattern style={{ width: '100%', background: '#fff', marginBottom: -7 }} />
      <Releases />
      <Mosaic />
    </Layout>
  )
}

export default IndexPage;
